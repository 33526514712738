/*global*/

.epPageTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 60px;
    color: #913a38;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #fff;
    padding: 0px 0px 0px 5px;
}

.epSpantitle {
    background-color: #231f20;
    color: #fff;
    padding: 0px 5px;
    margin: 0;
}

.epPageTitle1 {
    position: relative;
    font-size: 60px;
    color: #913a38;
    font-weight: bold !important;
    text-transform: uppercase;
    padding: 0px 0px 20px 5px;
}

/*Menu general*/
.epMenu{
    display: block;
    margin: auto;
    width: 35%;
}
.logo {
    width: 173px;
    height: 53px;
}

.btn-secondary.btn.Ripple-parent.dropdown-toggle.epBtnLg {
    background: transparent !important;
    color: #000;
    box-shadow: none;
    padding: 10px;
}

.show.dropdown-menu.epBtnLgDrop {
    min-width: 50px !important;
    top: -15px !important;
    border: none;
    background: none !important;
}

.epMobileList {
    list-style: none;
    display: flex;
    flex-flow: column nowrap;
    position: fixed;
    padding-top: 3.5rem;
}

.epMobileList li {
    padding: 18px 10px;
    font-size: 30px;
    text-transform: uppercase;
    position: relative;
}

.active {
    color: #913a38;
    font-size: 20px !important;
    font-family: "Roboto";
    font-weight: bold !important;
}

.case_button a {
    width: 100%;
}


/*style*/

.epFloatTextBox ul {
    list-style-type: none !important;
}

.epFloatTextBox ul li i {
    padding-right: 10px;
    color: #913a38;
}

.row.epFloatTextBox h3 {
    padding-bottom: 20px;
    color: #913a38;
    font-size: 30px;
}

.col-4.epColAboutSection {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 20px !important;
    margin: 10px !important;
    max-width: 30%;
}

.col-4.epColAboutSection p {
    flex-grow: 1;
}

.epCardButton {
    text-transform: uppercase;
    word-wrap: break-word;
    white-space: normal;
    cursor: pointer;
    border: 0;
    border-radius: .125rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: .84rem 2.14rem;
    font-size: .81rem;
    color: #fff;
    line-height: 1.5;
    display: inline-block;
    background-color: #913a38 !important;
    text-align: center;
    margin: 0 auto;
}

.epCardButton:hover {
    color: #fff !important;
    transform: scale(1.1);
}

.row.epFloatTextBox {
    padding: 30px;
}

.row.epFloatTextBox h3 {
    padding-bottom: 20px;
}

.epColAboutSection {
    padding: 0 !important;
    margin: 0 !important;
}

.epRowAboutSection {
    background: #fff;
    width: 70%;
    margin: 0 auto !important;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-top: -250px !important;
    margin-bottom: -250px !important;
    position: relative;
    -webkit-box-shadow: 6px 3px 36px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 6px 3px 36px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 6px 3px 36px 0px rgba(0, 0, 0, 0.75);
}

.epCard {
    background-color: #f1f1f1;
    background-image: url(./assets/images/footer-bkg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    -webkit-box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    padding: 60px 0px;
}

.epImgSection.img-fluid {
    height: 80vh !important;
    width: 100vw !important;
}

.container-fluid.scrollSections {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, -50%);
}

.flip-boxes {
    justify-content: center;
}

.flip-box {
    display: flex;
    align-content: stretch;
    min-height: 200px;
    flex-wrap: wrap;
    position: relative;
    border: 10px solid transparent;
    padding: 0;
    border-top: 0;
    -webkit-perspective: 1000;
    perspective: 1000;
}

.flip-box:hover .back {
    transform: rotateY(0deg);
    z-index: 10;
}

.flip-box:hover .front {
    transform: rotateY(180deg);
    z-index: -1;
}

.flip-box .back, .flip-box .front {
    position: relative;
    background-color: #1b2d61;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    flex: 0 0 100%;
    -webkit-transition: all 1s cubic-bezier(0.5, 1, 0.5, 1);
    transition: all 1s cubic-bezier(0.5, 1.3, 0.5, 1.3);
    transform-style: preserve-3d;
    background-size: cover;
    background-position: center;
}

.flip-box .back {
    background-color: #cecece;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    transform: rotateY(-180deg);
}

.flip-box .back:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 30px;
    border: 15px solid transparent;
    border-bottom-color: white;
    border-left-color: white;
}

.flip-box .front {
    z-index: 10;
}

.flip-box .front .content {
    font-size: 2rem;
}

.flip-box .front:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    border: 15px solid #cecece;
    border-bottom-color: white;
    border-right-color: white;
}

.flip-box .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    -webkit-transform: translateZ(50px);
    transform: translateZ(50px);
    text-shadow: 0px 0px 2px black;
}

.epFooter {
    background-color: #f1f1f1;
    background-image: url(./assets/images/footer-bkg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.5);
    padding-top: 60px;
}

.epFooterText {
    color: #913a38 !important;
    padding-left: 5% !important;
    padding-top: 30px;
}

.footer-copyright {
    background-color: #913a38 !important;
}

.svgContact {
    position: relative;
    bottom: -5px;
}

#bifa {
    border: 2px solid #999;
    animation: bounce 1s infinite;
    -webkit-animation: bounce 1s infinite;
}

@keyframes bounce {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-15px);
    }
}

@-webkit-keyframes bounce {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-15px);
    }
}

/*Mewnu*/

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    right: 15%;
    font-size: 3em;
    top: 10%;
    padding: 30px !important;
    z-index: 100;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: hidden !important;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #ffffffba !important;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0;
}

/*Menu*/

.page-home .overlay {
    background-color: rgba(166, 178, 206, 0) !important;
}

.btn-secondary.btn.Ripple-parent.dropdown-toggle.dropdownEpToggle {
    background: transparent !important;
    color: #fff;
    padding: 0;
    box-shadow: none;
}

.show.dropdown-menu.epDropDownMenu {
    padding: 0 !important;
    min-width: 1rem !important;
    margin: 0 !important;
}

.navbar-brand img {
    box-sizing: border-box;
    height: 60px;
}

.modal-dialog {
    max-width: none !important;
    margin: 0PX auto !important;
}

.modal-header {
    background-color: rgba(166, 178, 206, 0) !important;
    border: none !important;
}

.close {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000 !important;
    text-shadow: 0 1px 0 #fff;
}

.close:hover {
    color: #fff9f9;
    text-decoration: none;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0 !important;
}

.epMenu {
    padding-right: 0px !important;
    padding-left: 0px !important;
    z-index: 100 !important;
    background-color: #fff;
}

.hexagon-item:nth-last-child(3) {
    transform: rotate(30deg) translate(0px, 0px) !important;
}

.epCenter {
    padding-left: 120px !important;
    padding-right: 20px !important;
}

/*end menu */

::-moz-selection {
    background-color: #913a38;
    color: #fff;
}

::selection {
    background-color: #913a38;
    color: #fff;
}

/*
     *  Reset bootstrap's default style
     */

.form-control::-webkit-input-placeholder, ::-webkit-input-placeholder {
    opacity: 1;
    color: inherit;
}

.form-control:-moz-placeholder, :-moz-placeholder {
    /* Firefox 18- */
    opacity: 1;
    color: inherit;
}

.form-control::-moz-placeholder, ::-moz-placeholder {
    /* Firefox 19+ */
    opacity: 1;
    color: inherit;
}

.form-control:-ms-input-placeholder, :-ms-input-placeholder {
    opacity: 1;
    color: inherit;
}

button, input, select, textarea, label {
    font-weight: 400;
}

.btn {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.btn:hover, .btn:focus, .btn:active:focus {
    outline: 0 none;
}

.btn-primary {
    background-color: #913a38;
    border: 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    height: 48px;
    line-height: 50px;
    padding: 0 42px;
    text-transform: uppercase;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:active:focus {
    background-color: #f9423a;
}

.btn-border {
    border: 1px solid #d7d8db;
    display: inline-block;
    padding: 7px;
}

/*
     *  CSS Helper Class
     */

.clear:before, .clear:after {
    content: " ";
    display: table;
}

.clear:after {
    clear: both;
}

.pt-table {
    display: table;
    width: 100%;
    height: -webkit-calc(100vh - 4px);
    height: -moz-calc(100vh - 4px);
    height: calc(100vh - 4px);
}

.pt-tablecell {
    display: table-cell;
    vertical-align: middle;
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.relative {
    position: relative;
}

.primary, .link:hover {
    color: #913a38;
}

.no-gutter {
    margin-left: 0;
    margin-right: 0;
}

.no-gutter>[class^="col-"] {
    padding-left: 0;
    padding-right: 0;
}

.flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
}

.flex-middle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
}

.space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
}

.nicescroll-cursors {
    background: #913a38 !important;
}

.preloader {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
}

.preloader.active.hidden {
    display: none;
}

.loading-mask {
    background-color: #913a38;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 20%;
    -webkit-transition: all 0.6s cubic-bezier(0.61, 0, 0.6, 1) 0s;
    -moz-transition: all 0.6s cubic-bezier(0.61, 0, 0.6, 1) 0s;
    -o-transition: all 0.6s cubic-bezier(0.61, 0, 0.6, 1) 0s;
    transition: all 0.6s cubic-bezier(0.61, 0, 0.6, 1) 0s;
}

.loading-mask:nth-child(2) {
    left: 20%;
    -webkit-transition-delay: 0.1s;
    -moz-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.loading-mask:nth-child(3) {
    left: 40%;
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.loading-mask:nth-child(4) {
    left: 60%;
    -webkit-transition-delay: 0.3s;
    -moz-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.loading-mask:nth-child(5) {
    left: 80%;
    -webkit-transition-delay: 0.4s;
    -moz-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    transition-delay: 0.4s;
}

.preloader.active.done {
    z-index: 0;
}

.preloader.active .loading-mask {
    width: 0;
}

/*------------------------------------------------
        Start Styling
    -------------------------------------------------*/

.mt20 {
    margin-top: 20px;
}

.site-wrapper {
    border-top: 4px solid #913a38;
}

.page-close {
    font-size: 30px;
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 100;
}

.page-title {
    margin-bottom: 75px;
}

.page-title img {
    margin-bottom: 20px;
}

.page-title h2 {
    font-size: 68px;
    margin-bottom: 25px;
    position: relative;
    z-index: 0;
    font-weight: 900;
    text-transform: uppercase;
}

.page-title p {
    font-size: 16px;
}

.page-title .title-bg {
    color: rgba(30, 37, 48, 0.07);
    font-size: 158px;
    left: 0;
    letter-spacing: 10px;
    line-height: 0.7;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: -1;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.section-title {
    margin-bottom: 20px;
}

.section-title h3 {
    display: inline-block;
    position: relative;
}

.section-title h3::before, .section-title h3::after {
    content: "";
    height: 2px;
    position: absolute;
    bottom: 8px;
    left: -webkit-calc(100% + 14px);
    left: -moz-calc(100% + 14px);
    left: calc(100% + 14px);
}

.section-title h3::before {
    background-color: #1b1818;
    width: 96px;
    bottom: 14px;
}

.section-title h3::after {
    background-color: #913a38;
    width: 73px;
}

.section-title.light h3 {
    color: #fff;
}

.section-title.light h3::before {
    background-color: #fff;
}

.page-nav {
    bottom: 40px;
    left: 0;
    position: absolute;
    right: 0;
}

.page-nav span {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 0.9;
    text-transform: uppercase;
}

/*------------------------------------------------
        Home Page
    -------------------------------------------------*/

.hexagon-item:first-child {
    margin-left: 0;
}

.page-home {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    vertical-align: middle;
}

.page-home .overlay {
    background-color: rgba(14, 17, 24, 0.97);
}

.ep-container {
    width: 50%;
}

/* End of container */

.hexagon-item {
    cursor: pointer;
    width: 200px;
    height: 173.20508px;
    float: left;
    margin-left: -29px;
    z-index: 0;
    position: relative;
    -webkit-transform: rotate(30deg);
    -moz-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    -o-transform: rotate(30deg);
    transform: rotate(30deg);
}

.hexagon-item:first-child {
    margin-left: 0;
}

.hexagon-item:hover {
    z-index: 1;
}

.hexagon-item:hover .hex-item:last-child {
    opacity: 1;
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);
}

.hexagon-item:hover .hex-item:first-child {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
}

.hexagon-item:hover .hex-item:first-child div:before, .hexagon-item:hover .hex-item:first-child div:after {
    height: 5px;
}

.hexagon-item:hover .hex-item div::before, .hexagon-item:hover .hex-item div::after {
    background-color: #913a38;
}

.hexagon-item:hover .hex-content svg {
    -webkit-transform: scale(0.97);
    -moz-transform: scale(0.97);
    -ms-transform: scale(0.97);
    -o-transform: scale(0.97);
    transform: scale(0.97);
}

.page-home .hexagon-item:nth-last-child(1), .page-home .hexagon-item:nth-last-child(2), .page-home .hexagon-item:nth-last-child(3) {
    -webkit-transform: rotate(30deg) translate(87px, -80px);
    -moz-transform: rotate(30deg) translate(87px, -80px);
    -ms-transform: rotate(30deg) translate(87px, -80px);
    -o-transform: rotate(30deg) translate(87px, -80px);
    transform: rotate(30deg) translate(87px, -80px);
}

.hex-item {
    position: absolute;
    top: 0;
    left: 50px;
    width: 100px;
    height: 173.20508px;
}

.hex-item:first-child {
    z-index: 0;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hex-item:last-child {
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 1;
}

.hex-item div {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    width: 100px;
    height: 173.20508px;
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
}

.hex-item div::before, .hex-item div::after {
    background-color: #1b1818;
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.hex-item div:before {
    top: 0;
}

.hex-item div:after {
    bottom: 0;
}

.hex-item div:nth-child(1) {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
}

.hex-item div:nth-child(2) {
    -webkit-transform: rotate(60deg);
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -o-transform: rotate(60deg);
    transform: rotate(60deg);
}

.hex-item div:nth-child(3) {
    -webkit-transform: rotate(120deg);
    -moz-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    -o-transform: rotate(120deg);
    transform: rotate(120deg);
}

.hex-content {
    color: #fff;
    display: block;
    height: 180px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    transform: rotate(-30deg);
    width: 156px;
}

.hex-content .hex-content-inner {
    left: 50%;
    margin: -3px 0 0 2px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.hex-content .icon {
    display: block;
    font-size: 36px;
    line-height: 30px;
    margin-bottom: 11px;
}

.hex-content .title {
    display: block;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 24px;
    text-transform: uppercase;
}

.hex-content svg {
    left: -7px;
    position: absolute;
    top: -13px;
    transform: scale(0.87);
    z-index: -1;
    -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.hex-content:hover {
    color: #fff;
}

.page-home .hexagon-item:nth-last-child(1), .page-home .hexagon-item:nth-last-child(2), .page-home .hexagon-item:nth-last-child(3) {
    -webkit-transform: rotate(30deg) translate(87px, -80px);
    -moz-transform: rotate(30deg) translate(87px, -80px);
    -ms-transform: rotate(30deg) translate(87px, -80px);
    -o-transform: rotate(30deg) translate(87px, -80px);
    transform: rotate(30deg) translate(87px, -80px);
}

/*------------------------------------------------
        Welcome Page
    -------------------------------------------------*/

.author-image-large {
    position: absolute;
    right: 0;
    top: 0;
}

.author-image-large img {
    height: -webkit-calc(100vh - 4px);
    height: -moz-calc(100vh - 4px);
    height: calc(100vh - 4px);
}

@media (min-width: 1200px) {
    .col-lg-offset-2 {
        margin-left: 16.66666667%;
    }
}

@media (min-width: 1200px) {
    .col-lg-8 {
        width: 66.66666667%;
    }
}

.hexagon-item:first-child {
    margin-left: 0;
}

.pt-table.desktop-768 .pt-tablecell {

}

.hexagon-item:hover .icon i {
    color: #913a38;
    transition: 0.6s;
}

.hexagon-item:hover .title {
    -webkit-animation: focus-in-contract 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: focus-in-contract 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/***************************/

@-webkit-keyframes focus-in-contract {
    0% {
        letter-spacing: 1em;
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

@keyframes focus-in-contract {
    0% {
        letter-spacing: 1em;
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

@media only screen and (max-width: 767px) {
    .hexagon-item {
        float: none;
        margin: 0 auto 50px;
    }
    .hexagon-item:first-child {
        margin-left: auto;
    }
    .page-home .hexagon-item:nth-last-child(1), .page-home .hexagon-item:nth-last-child(2), .page-home .hexagon-item:nth-last-child(3) {
        -webkit-transform: rotate(30deg) translate(0px, 0px);
        -moz-transform: rotate(30deg) translate(0px, 0px);
        -ms-transform: rotate(30deg) translate(0px, 0px);
        -o-transform: rotate(30deg) translate(0px, 0px);
        transform: rotate(30deg) translate(0px, 0px);
    }
}

/*SMI*/

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-links {
    display: flex;
}

.social-btn {
    cursor: pointer;
    height: 50px !important;
    width: 50px;
    font-family: 'Titillium Web', sans-serif;
    color: #333;
    border-radius: 10px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    background: white;
    margin: 5px;
    transition: 1s;
}

.social-btn span {
    width: 0px;
    overflow: hidden;
    transition: 1s;
    text-align: center;
}

.svgStyle {
    height: 32px;
    width: 32px;
}

.svgStyle svg {
    width: 30px;
    height: 30px;
}

.social-btn:hover {
    width: 100px;
    border-radius: 5px;
}

.social-btn:hover span {
    padding: 5px;
    width: max-content;
}

#twitter svg {
    fill: #1da1f2;
}

#linkedin svg {
    fill: #0e76a8;
}

#github {
    fill: #333;
}

/*Contact*/

.epContactHeader {
    background-color: #913a38 !important;
    padding: 30px;
    position: relative;
    margin-top: -60px !important;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
}

.epAccent-1 {
    background-color: #913a38 !important;
}

.epContactBg {
    background-image: url('./assets/images/bg.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0 15px !important;
    padding-bottom: 300px;
}

/*HOME*/

/*VIdeo */

.cardsSection {
    min-height: 100vh !important;
}

.card_1 {
    min-height: 100vh;
    display: flex;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden;
    cursor: default;
    flex: 1 !important;
}

.case_text_container {
    position: absolute;
    bottom: 5%;
    z-index: 2;
    display: flex;
    width: 80%;
    margin-bottom: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    color: #fff;
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.cardBg:hover .case_text_container {
    opacity: 0;
    transform: translateY(200px)
}

.text_mask {
    overflow: hidden;
}

.case_text_name {
    margin-top: 0;
    margin-bottom: 20px;
    margin-left: 0;
    color: #fff;
    font-size: 45px;
    font-weight: 500;
}

.case_text_description {
    max-width: 353px;
    margin-bottom: 0;
    margin-left: 4px;
    color: #a8a8a8;
    font-size: 16px;
    line-height: 170%;
    font-weight: 400;
}

.case_button {
    z-index: 100;
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    transform: translateY(400px);
    transition: transform 0.75s ease-in;
}

.case_button button {
    background-color: rgba(145, 58, 56, 0.5);
    color: aliceblue !important;
    border: none;
    padding: 40px 5px;
    font-size: 3em;
    width: 100%;
}

.cardBg:hover .case_button {
    transform: translateY(0px);
}

.cardBg_1 {
    background-image: linear-gradient(180deg, transparent 60%, #000), url(./assets/images/home1.png);
    background-position: 0 0, 45% 50%;
    background-size: auto, cover;
    z-index: 1;
}

.cardBg_2 {
    background-image: linear-gradient(180deg, transparent 60%, #000), url(./assets/images/home2.png);
    background-position: 0 0, 45% 50%;
    background-size: auto, cover;
    z-index: 2;
}

.cardBg {
    position: relative;
    display: flex;
    width: 100%;
    min-width: 0;
    padding-top: 0;
    padding-bottom: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-perspective-origin: 0 50%;
    perspective-origin: 0 50%;
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition: min-width .6s cubic-bezier(.77, 0, .175, 1);
    transition: min-width .6s cubic-bezier(.77, 0, .175, 1);
    text-decoration: none;
}

.cardBg:hover {
    min-width: 60vw !important;
    width: 100%;
}

.videoBg {
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, transparent, #000);
    opacity: 0;
    -webkit-transition: opacity 1.6s cubic-bezier(.455, .03, .515, .955);
    transition: opacity 1.6s cubic-bezier(.455, .03, .515, .955);
    object-fit: cover;
}

.videoBg #myVideo1 {
    height: 100%;
}

.videoBg:hover {
    opacity: 1;
}

#myVideo {
    min-width: 100%;
}

.videoBackground {
    padding: 0 !important;
    margin: 0 !important;
    height: 92vh !important;
    overflow: hidden;
}

.scrollButton {
    position: absolute;
    left: 50%;
    bottom: 10px;
    z-index: 5;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(60px);
    }
}

svg #wheel {
    animation: scroll ease 2s infinite;
}

.overlay {
    background: #ffffff42;
    top: 10% !important;
}

#logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 646px !important;
    height: 233px !important;
    z-index: 10;
}

#logo ellipse {
    animation: ellipseEP 2.8s ease-in;
}

#logo path:nth-child(1) {
    stroke-dasharray: 38px;
    stroke-dashoffset: 38px;
    animation: line-anim 2s ease forwards 1.4s, fillRail 0.8s ease forwards 2.8s;
}

#logo path:nth-child(2) {
    stroke-dasharray: 48px;
    stroke-dashoffset: 48px;
    animation: line-anim 2s ease forwards 1.5s, fillRail 0.8s ease forwards 2.8s;
}

#logo path:nth-child(3) {
    stroke-dasharray: 69px;
    stroke-dashoffset: 69px;
    animation: line-anim 2s ease forwards 1.6s, fillRail 0.8s ease forwards 2.8s;
}

#logo path:nth-child(4) {
    stroke-dasharray: 39px;
    stroke-dashoffset: 39px;
    animation: line-anim 2s ease forwards 1.7s, fillRail 0.8s ease forwards 2.8s;
}

#logo path:nth-child(5) {
    stroke-dasharray: 46px;
    stroke-dashoffset: 46px;
    animation: line-anim 2s ease forwards 1.8s, fillRail 0.8s ease forwards 2.8s;
}

#logo path:nth-child(6) {
    stroke-dasharray: 28px;
    stroke-dashoffset: 28px;
    animation: line-anim 2s ease forwards 1.9s, fillRail 0.8s ease forwards 2.8s;
}

#logo path:nth-child(7) {
    stroke-dasharray: 48px;
    stroke-dashoffset: 48px;
    animation: line-anim 2s ease forwards 2s, fillRail 0.8s ease forwards 2.8s;
}

#logo path:nth-child(8) {
    stroke-dasharray: 38px;
    stroke-dashoffset: 38px;
    animation: line-anim 2s ease forwards 2.1s, fillRail 0.8s ease forwards 2.8s;
}

#logo path:nth-child(9) {
    stroke-dasharray: 39px;
    stroke-dashoffset: 39px;
    animation: line-anim 2s ease forwards 2.2s, fillRail 0.8s ease forwards 2.8s;
}

#logo path:nth-child(10) {
    stroke-dasharray: 46px;
    stroke-dashoffset: 46px;
    animation: line-anim 2s ease forwards 2.3s, fillRail 0.8s ease forwards 2.8s;
}

#logo path:nth-child(11) {
    stroke-dasharray: 32px;
    stroke-dashoffset: 32px;
    animation: line-anim 2s ease forwards 2.4s, fillRail 0.8s ease forwards 2.8s;
}

#logo path:nth-child(12) {
    stroke-dasharray: 48px;
    stroke-dashoffset: 48px;
    animation: line-anim 2s ease forwards 2.5s, fillRail 0.8s ease forwards 2.8s;
}

#logo path:nth-child(13) {
    stroke-dasharray: 46px;
    stroke-dashoffset: 46px;
    animation: line-anim 2s ease forwards 2.6s, fillRail 0.8s ease forwards 2.8s;
}

#logo path:nth-child(14) {
    stroke-dasharray: 65px;
    stroke-dashoffset: 65px;
    animation: line-anim 2s ease forwards 2.7s, fillRail 0.8s ease forwards 2.8s;
}

#logo path:nth-child(15) {
    stroke-dasharray: 154px;
    stroke-dashoffset: 154px;
    animation: line-anim 2s ease forwards 0.2s, fillEp 0.8s ease forwards 2.8s;
}

#logo path:nth-child(16) {
    stroke-dasharray: 149px;
    stroke-dashoffset: 149px;
    animation: line-anim 2s ease forwards 0.4s, fillEp 0.8s ease forwards 2.8s;
}

#logo path:nth-child(17) {
    stroke-dasharray: 183px;
    stroke-dashoffset: 183px;
    animation: line-anim 2s ease forwards 0.6s, fillRail 0.8s ease forwards 2.8s;
}

#logo path:nth-child(18) {
    stroke-dasharray: 163px;
    stroke-dashoffset: 163px;
    animation: line-anim 2s ease forwards 0.8s, fillRail 0.8s ease forwards 2.8s;
}

#logo path:nth-child(19) {
    stroke-dasharray: 75px;
    stroke-dashoffset: 75px;
    animation: line-anim 2s ease forwards 1s, fillRail 0.8s ease forwards 2.8s;
}

#logo path:nth-child(20) {
    stroke-dasharray: 99px;
    stroke-dashoffset: 99px;
    animation: line-anim 2s ease forwards 1.2s, fillRail 0.8s ease forwards 2.8s;
}

@keyframes line-anim {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes fillEp {
    from {
        fill: transparent;
    }
    to {
        fill: #913A38;
    }
}

@keyframes fillRail {
    from {
        fill: transparent;
    }
    to {
        fill: #1F1A17;
    }
}

@keyframes ellipseEP {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/*lOGIN*/

.epLoginContainer {
    background-image: linear-gradient(180deg, transparent 60%, #000), url(./assets/images/img04.jpg);
    background-position: 0 0, 45% 50%;
    background-size: auto, cover;
    height: 90vh;
    padding: 60px 60px;
}

.epLoginCol {
    margin: 0 auto;
}

.btn-danger.btn.Ripple-parent.btn-block.z-depth-2 {
    background-color: #8f3735 !important;
    transition: all .2s ease-in-out
}

.btn-danger.btn.Ripple-parent.btn-block.z-depth-2:hover {
    transform: scale(1.1);
}

.form-simple .font-small {
    font-size: 0.8rem;
}

.form-simple .header {
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}

.form-simple input[type=text]:focus:not([readonly]) {
    border-bottom: 1px solid #ff3547;
    -webkit-box-shadow: 0 1px 0 0 #ff3547;
    box-shadow: 0 1px 0 0 #ff3547;
}

.form-simple input[type=text]:focus:not([readonly])+label {
    color: #4f4f4f;
}

.form-simple input[type=password]:focus:not([readonly]) {
    border-bottom: 1px solid #ff3547;
    -webkit-box-shadow: 0 1px 0 0 #ff3547;
    box-shadow: 0 1px 0 0 #ff3547;
}

.form-simple input[type=password]:focus:not([readonly])+label {
    color: #4f4f4f;
}

/*LOCK*/

.btn-lock {
    position: relative;
    top: calc(50% - 32px);
    left: calc(10% - 32px);
    display: inline-block;
    background: #ff5b5b;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    padding: 5px 0 0 11px;
    border-radius: 50%;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.btn-lock p {
    display: inline-block;
    width: 300px;
    padding: 0px 30px;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
}

.btn-lock svg {
    fill: none;
    transform: translate3d(0, 0, 0);
}

.btn-lock svg .bling {
    stroke: #fff;
    stroke-width: 2.5;
    stroke-linecap: round;
    stroke-dasharray: 3;
    stroke-dashoffset: 15;
    transition: all 0.3s ease;
}

.btn-lock svg .lock {
    stroke: #fff;
    stroke-width: 4;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-dasharray: 36;
    transition: all 0.4s ease;
}

.btn-lock svg .lockb {
    fill: #fff;
    fill-rule: evenodd;
    clip-rule: evenodd;
    transform: rotate(8deg);
    transform-origin: 14px 20px;
    transition: all 0.2s ease;
}

#inpLock {
    display: none;
}

#inpLock:checked+label {
    background: #20cca5;
}

#inpLock:checked+label svg {
    opacity: 1;
}

#inpLock:checked+label svg .bling {
    animation: bling 0.3s linear forwards;
    animation-delay: 0.2s;
}

#inpLock:checked+label svg .lock {
    stroke-dasharray: 48;
    animation: locked 0.3s linear forwards;
}

#inpLock:checked+label svg .lockb {
    transform: rotate(0);
    transform-origin: 14px 22px;
}

@-moz-keyframes bling {
    50% {
        stroke-dasharray: 3;
        stroke-dashoffset: 12;
    }
    100% {
        stroke-dasharray: 3;
        stroke-dashoffset: 9;
    }
}

@-webkit-keyframes bling {
    50% {
        stroke-dasharray: 3;
        stroke-dashoffset: 12;
    }
    100% {
        stroke-dasharray: 3;
        stroke-dashoffset: 9;
    }
}

@-o-keyframes bling {
    50% {
        stroke-dasharray: 3;
        stroke-dashoffset: 12;
    }
    100% {
        stroke-dasharray: 3;
        stroke-dashoffset: 9;
    }
}

@keyframes bling {
    50% {
        stroke-dasharray: 3;
        stroke-dashoffset: 12;
    }
    100% {
        stroke-dasharray: 3;
        stroke-dashoffset: 9;
    }
}

@-moz-keyframes locked {
    50% {
        transform: translateY(1px);
    }
}

@-webkit-keyframes locked {
    50% {
        transform: translateY(1px);
    }
}

@-o-keyframes locked {
    50% {
        transform: translateY(1px);
    }
}

@keyframes locked {
    50% {
        transform: translateY(1px);
    }
}

/*REALWAY FLEAT*/

.container-fluid.epTitleContainer {
    background: #f4f4f4;
    margin: 0;
    padding: 30px 40px;
}

.container-fluid.epTitleContainer h1 {
    text-align: center;
    color: #8f3735;
    padding-bottom: 60px;
}

.epRealText {
    text-align: center;
}

.bigBox {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 30px;
    min-height: 70vh;
    background: #f4f4f4;
}

.box {
    position: relative;
    width: 200px;
    height: 200px;
    transform-style: preserve-3d;
    animation: animate 20s linear infinite;
    margin: 0 auto;
    z-index: 2;
}

@keyframes animate {
    0% {
        transform: perspective(1000px) rotateX(-15deg) rotateY(0deg);
    }
    100% {
        transform: perspective(1000px) rotateX(-15deg) rotateY(360deg);
    }
}

.box span:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
    transform: rotateY(60deg) translateZ(200px);
}

.box span:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
    transform: rotateY(120deg) translateZ(200px);
}

.box span:nth-child(3) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
    transform: rotateY(180deg) translateZ(200px);
}

.box span:nth-child(4) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
    transform: rotateY(240deg) translateZ(200px);
}

.box span:nth-child(5) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
    transform: rotateY(300deg) translateZ(200px);
}

.box span:nth-child(6) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
    transform: rotateY(360deg) translateZ(200px);
}

.box span img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bigLatter {
    font-size: 600px;
    font-family: "Martel";
    margin: 0 auto;
    color: #913a38;
    position: absolute;
    top: -50%;
    z-index: 1;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -40%);
}


/*SERVICE*/

.container-fluid.epTitleContainer {
    background: #f4f4f4;
    margin: 0;
    padding: 30px 40px;
}

.container-fluid.epTitleContainer h1 {
    color: #8f3735;
    padding-bottom: 60px;
}

.container-fluid.epServiceCTA {
    margin: 0;
    padding: 30px 80px;
    text-align: center;
    background-color: #1B2936;
    -webkit-box-shadow: inset 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
    box-shadow: inset 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
}

.container-fluid.epServiceCTA h1 {
    color: #fff;
    font-weight: bold;
}

.devider {
    height: 3px;
    background: #913a38;
    width: 70px;
    margin: 0 auto;
}

.epCTAText {
    color: #fff;
    padding: 40px 0px;
    font-size: 26px;
    width: 60%;
    margin: 0 auto;
}


/*tEAM*/
.epTeamBg{
    background-image: url('./assets/images/bg.png');
    background-position: center;
  
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0 15px !important;
    
}
.epTeamCard{
    background: transparent !important;
    box-shadow: none !important;
}
.epTeamIcon {
    color: #a43432;
}


/*pHOTOS*/

.ePgalleryRow {
    margin-bottom: 20px;
}
.ePtitle {
    padding: 30px 20px;
    color: #913a38;
}
.ePheader{
    position: relative;
}
.ePdivider {
    width: 15%;
    background: #913a50;
    height: 3px;
    position: absolute;
    bottom: 30px;
    left: 20px;
}



.epPoze {
    border: none !important;
    background-color: unset !important;
    border-radius: unset !important;
    padding: unset !important;
    margin-bottom: 10px;
}
.epRowPoze{
    margin-bottom: 20px;
}

.epCardPoze{
    margin-bottom:15px;
}

.epCardImgPoze {
    min-height: 250px !important;
}


.epCardBody {
    background: #913a38;
    text-align: center;
}

.epCardBtn {
    color: #fff;
    font-size: 18px;
    text-align: center;
}

.epCardBtn:hover{
    color:#000;
}



@media only screen and (max-width: 425px) {
    /*menu*/
    .logo {
        width: 40%;
        height: auto;
    }
    /*end menu*/
    /*home*/
    .videoBackground {
        padding: 0 !important;
        margin: 0 !important;
        height: auto !important;
        overflow: hidden;
    }
    #logo {
        position: absolute;
        top: 25% !important;
        left: 50%;
        transform: translate(-50%, -25%);
        width: 300px !important;
        height: 97px !important;
        z-index: 10;
    }
    .scrollButton {
        display: none;
    }
    .row.cardsRow {
        flex-direction: column;
    }
    .col-6.card_1.cardBg_1.cardBg {
        max-width: 100% !important;
    }
    .col-6.card_1.cardBg_2.cardBg {
        max-width: 100%;
    }
    .card_1 {
        min-height: 80vh;
    }
    .case_button {
        flex-direction: column;
    }
    /*end Home*/
    /*About*/
    .epImgSection.img-fluid {
        height: 30vh !important;
        width: 100vw !important;
    }
    .epRowAboutSection {
        background: #fff;
        width: 100%;
        margin: 0 auto !important;
        margin-bottom: 0px;
        margin-top: 0px;
        margin-top: -100px !important;
        margin-bottom: -100px !important;
        position: relative;
        box-shadow: 6px 3px 36px 0px rgba(0, 0, 0, 0.75);
    }
    .epPageTitle {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -25%);
        font-size: 35px;
        color: #913a38;
        font-weight: bold;
        text-transform: uppercase;
        background-color: #fff;
        padding: 0px 0px 0px 5px;
        top: 25%;
    }
    .col-4.epColAboutSection {
        flex: auto !important;
        max-width: 92%;
    }
    .row.epAboutSecondRow {
        flex-direction: column;
        align-items: center;
        margin: 0 !important;
    }
    /*End About*/
    /*Realway*/
    .epPageTitle1 {
        position: relative;
        left: 50%;
        transform: translate(-50%, -25%);
        font-size: 35px;
        color: #913a38;
        font-weight: bold;
        text-transform: uppercase;
        padding: 0px 0px 0px 5px;
        top: 25%;
        margin-top: 20px;
    }
    .container-fluid.epTitleContainer h1 {
        color: #8f3735;
        padding-bottom: 30px;
    }
    .col-6.epRealText {
        max-width: 100% !important;
        margin-bottom: 20px;
    }
    .col-6.epRealText h1 {
        font-size: 30px;
        padding-bottom: 20px;
    }
    .row.epImgRow {
        flex-direction: column;
        align-items: center;
    }
    .box span:nth-child(1) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: center;
        transform-style: preserve-3d;
        transform: rotateY(60deg) translateZ(60px);
    }
    .box span:nth-child(2) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: center;
        transform-style: preserve-3d;
        transform: rotateY(120deg) translateZ(60px);
    }
    .box span:nth-child(3) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: center;
        transform-style: preserve-3d;
        transform: rotateY(180deg) translateZ(60px);
    }
    .box span:nth-child(4) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: center;
        transform-style: preserve-3d;
        transform: rotateY(240deg) translateZ(60px);
    }
    .box span:nth-child(5) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: center;
        transform-style: preserve-3d;
        transform: rotateY(300deg) translateZ(60px);
    }
    .box span:nth-child(6) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: center;
        transform-style: preserve-3d;
        transform: rotateY(360deg) translateZ(60px);
    }
    .bigLatter {
        display: none;
    }
    /*Services*/
    .row.epFloatTextBox {
        flex-direction: column;
    }
    .col-6.col-xs-2.epServiceText {
        max-width: 100%;
    }
    .container-fluid.epServiceCTA {
        margin: 0;
        padding: 30px 25px;
        text-align: center;
        background-color: #1B2936;
        box-shadow: inset 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
    }
    .epCTAText {
        color: #fff;
        padding: 40px 0px;
        font-size: 26px;
        width: 100%;
        margin: 0 auto;
    }
    /*LogIn*/
    .col-4.epLoginCol {
        width: 100% !important;
        max-width: 100%;
        flex: 1 !important;
    }
    /*footer*/
    .svgContact {
        position: relative;
        bottom: -50px;
        width: 100%;
        overflow: hidden;
    }
    /*end footer*/
}