.epContactHeader {
    background-color: #913a38 !important;
    padding: 30px;
    position: relative;
    margin-top: -60px !important;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
}

.epAccent-1 {
    background-color: #913a38 !important;
}

.epContactBg {
    background-image: url('../../assets/images/bg.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0 15px !important;
    padding-bottom: 300px;
}

.agree {
    margin-left: 25px;
    margin-bottom: 25px;
}